/* global CSVJSON */
import React from 'react';
import PropTypes from 'prop-types';
import {ColumnChart} from '@toast-ui/react-chart';

class ChartOfCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      casesData: [],
    };
  }

  componentDidMount() {
    const { csv } = this.props;
    fetch(csv)
      .then(response => response.text())
      .then(csvData => {
        this.setState({
          casesData: CSVJSON.csv2json(
            csvData,
            {
              parseNumbers: true,
            },
          ).map(
            (entry, i, casesData) => {
              const increase = i ?
                entry.cases - casesData[i-1].cases :
                entry.cases;

              return {
                ...entry,
                increase,
              };
            }
          ),
          isLoaded: true,
        });
      });
  }

  render() {
    const {
      casesData,
      error,
      isLoaded,
    } = this.state;
    console.log({
      casesData,
    });
    const increases = casesData.map(
      entry => entry.increase,
    );
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    }
    const data = {
        categories: casesData.map(
          entry => entry.date
        ),
        series: [
          {
            name: 'Berks',
            data: increases,
          },
        ],
    };
    const options = {
      chart: {
        width: 1200,
        height: 600,
        title: 'Berks County Pennsylvania New COVID-19 Cases Per Day'
      },
      yAxis: {
        title: 'Number of New Cases'
      },
      xAxis: {
        title: 'Date'
      }
    };
    return (
      <ColumnChart
        data={data}
        options={options}
      />
    );
  }
}

ChartOfCases.propTypes = {
  csv: PropTypes.string.isRequired,
}

export default ChartOfCases;
