import React from 'react';
import ChartOfCases from './components/ChartOfCases.js';
import csv from './data/42011/data.csv';
import './App.css';

function App() {
  return (
    <div className="App">
      <ChartOfCases
        csv={csv}
      />
    </div>
  );
}

export default App;
